import "./ConnectType.css"

interface Props {
  name: string
  icon: string
}

const ConnectType = (props: Props) => {
  const { name, icon } = props

  return (
    <>
      {name && icon && (
        <div className="connects">
          <img src={icon} alt={name} />
          <div className="title">{name}</div>
        </div>
      )}
    </>
  )
}

export default ConnectType