import { useState } from "react";
import ConnectType from "./ConnectType";
import Popover from '@mui/material/Popover';
import { useWallet, WalletStatus } from "@terra-money/wallet-kit";
import { useWallet as useWalletGalaxy, WalletStatus as WalletStatusGalaxy } from "@hexxagon/wallet-kit";

export const ConnectWallet = () => {
  const {
    status,
    availableWallets,
    connect,
    disconnect,
  } = useWallet();

  const { 
    status: statusGalaxy, 
    connect: connectGalaxy, 
    availableWallets: availableWalletsGalaxy, 
    disconnect: disconnectGalaxy
  } = useWalletGalaxy();

  const [anchorEl, setAnchorEl] = useState<any>(0);
  const open = Boolean(anchorEl);

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <section>
        {(status === WalletStatus.NOT_CONNECTED && statusGalaxy == WalletStatusGalaxy.NOT_CONNECTED) && (
          <button onClick={handleClick} className="btnConect">
            Connect Wallet
          </button>
        )}
        {(status === WalletStatus.CONNECTED || statusGalaxy === WalletStatusGalaxy.CONNECTED) && (
          <button onClick={() => {  
            try {
              if (status === WalletStatus.CONNECTED) {
                disconnect();
              }
              if (statusGalaxy === WalletStatusGalaxy.CONNECTED) {
                disconnectGalaxy();
              }
            } catch (error) {
              console.error("Erro ao desconectar a carteira", error)
            }
          }} className="btnConect">
            Disconnect
          </button>
        )}


      </section>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: '#1c1c1c',
            boxShadow: 'none',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="popupConnect">
          <div>
            {availableWalletsGalaxy.map(({ id, name, icon}) => (
              <div
                key={id}
                onClick={() => {
                  connectGalaxy(id);
                  handleClose();
                }}
              >
                <ConnectType name={name} icon={icon} />
              </div>
            ))}
            {availableWallets.map(({ id, name, icon }) => (
              <div
                key={id}
                onClick={() => {
                  connect(id);
                  handleClose();
                }}
              >
                <ConnectType name={name} icon={icon} />
              </div>
            ))}
          </div>
        </div>
      </Popover>
    </div>
  );
};
