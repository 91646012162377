
import { useState } from 'react';
import Button from '@mui/material/Button';

interface MenuProps {
  route: any;
}

const Menu = (menu: MenuProps) => {

  const [selected, setSelected] = useState<string>('stake');

  const redirectSwap = () => {
    //setSelected('swap');
    //menu.route('swap');
  }

  const redirectStake = () => {
    setSelected('stake');
    menu.route('stake');
  }

  const redirectStation = () => {
    window.open('https://station.terra.money', '_blank');
  }

  const redirectWP = () => {
    window.open('https://terratritium.com/assets/whitepaper/TerraTritiumWhitepaper.pdf', '_blank'); 
  }

  const selectedStyle = {backgroundColor: '#db7901'};

  return (
    <div className="Menu">
      <Button variant="contained" style={selected == "stake" ? selectedStyle : {}} onClick={redirectStake}>Stake</Button>
      {/* <Button variant="contained" style={selected == "swap" ? selectedStyle : {}} onClick={redirectSwap}>Swap</Button> */}
      <Button variant="contained" onClick={redirectStation}>Station</Button>
      <Button variant="contained" onClick={redirectWP}>Whitepaper</Button>
    </div>
  );
};

export default Menu;