import { Row, Col } from "components/layout/Grid";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState } from "react";
import { tritReferenceWallet } from "contract/config";

interface SelectedCoinProps {
  symbol: string;
  logo: string;
  name: string;
  setTritium: any;
  calcTrit: any;
  click: any;
  simulate: any;
}

const SelectedCoin = (props: SelectedCoinProps) => {
  const [inputValue, setInputValue] = useState(""); 

  function validarNumber(input: any) {
    // Remove qualquer ponto decimal ou valor não numérico
    input.value = input.value.replace(/\D/g, '');
  
    // Garante que o valor seja um número inteiro
    if (input.value % 1 !== 0) {
      // Se não for um número inteiro, limpa o campo
      input.value = '';
    }
  }

  return (
    <Row>
      <Col>
        <span
          className="logoCoin"
          onClick={props.click}
          style={{ cursor: 'pointer' }}
        >
          <img src={props.logo} alt={props.name} />
        </span>
      </Col>
      <Col>
        <div
          className="changeCoin"
          onClick={props.click}
          style={{ cursor: 'pointer' }}
        >
          <Row>
            <Col>
              <div className="symbolCoin">
                {props.symbol}{" "}
                <span className="selectCoin">
                  <KeyboardArrowDownIcon />
                </span>
              </div>
              <div className="nameCoin">{props.name}</div>
            </Col>
          </Row>
        </div>
      </Col>
      <Col span={10}>
        <span className="valueCoin">
          <input
            type="number"
            placeholder="0.000000"
            value={inputValue}
            onInput={(e) => validarNumber(e.target)}
            onChange={(e) => {
              setInputValue(e.target.value);
              props.setTritium(props.calcTrit(Number(e.target.value), props.symbol))
              props.simulate(e.target.value);
            }}
          />          
        </span>        
      </Col>
      {/* <div className="maxBalance">         
        {props.symbol && (
          <span>Ref: {props.ref || tritReferenceWallet}</span>
        )}
      </div> */}
    </Row>
  );
}

export default SelectedCoin;