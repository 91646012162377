import { LCDClient } from "@terra-money/feather.js";
import { tokenAddress } from "./address";
import { columbus5, nftOriginsContract } from "contract/config";

export const getTritBalance = async (walletAddress: string) => {
  const lcd = new LCDClient(columbus5);
  return await lcd.wasm.contractQuery(tokenAddress, { balance: { address: walletAddress } });
}

export const nftPresent = async (walletAddress: string) => {
  const lcd = new LCDClient(columbus5);
  return await lcd.wasm.contractQuery(nftOriginsContract, { tokens_with_info: { owner: walletAddress } });
  // console.log(result);
  // return true;
}
