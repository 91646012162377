import { PropsWithChildren } from "react"
import { maintenance } from "contract/config";

interface Props {

}

const Content = ({ children }: PropsWithChildren<Props>) => {
  return (
    <div className="content">
      {maintenance && (<div style={{
        fontSize: "18px",
        fontWeight: "600",
        color: "#000",
        padding: "8px",
        backgroundColor: "#00ffe1",
        width: "70%",
        textAlign: "center",
        margin: "auto",
      }}>
        <>
          {/* <img src="/message2.jpeg" alt="Terra Tritium Token" style={{ width: "100%" }} /> */}
          <p>Galactic Starship Maintenance: System Upgrade in Progress </p>
          <p>Back very soon</p>
        </>
      </div>)}
      {!maintenance && (children)}
    </div>
  );
};

export default Content;