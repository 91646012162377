import { getInitialConfig, WalletProvider } from "@terra-money/wallet-kit";
import {
  WalletProvider as GalaxyWalletProvider,
  getInitialConfig as getInitialConfigGalaxy,
} from "@hexxagon/wallet-kit"
import TerraStationMobileWallet from "@terra-money/terra-station-mobile";
import { StrictMode } from "react"
import { BrowserRouter } from "react-router-dom"
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root")!);

Promise.all([getInitialConfig(), getInitialConfigGalaxy()]).then(
  ([config, defaultNetworks]) => {
  root.render(
    <StrictMode>
      <BrowserRouter>
        <WalletProvider extraWallets={[new TerraStationMobileWallet()]} defaultNetworks={config}>
          <GalaxyWalletProvider defaultNetworks={defaultNetworks}>
            <App />
          </GalaxyWalletProvider>
        </WalletProvider>
      </BrowserRouter>
    </StrictMode>
  );
});
