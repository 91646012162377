import { useState, useRef } from "react";
import { Row, Col } from "./layout/Grid";
import ScifiButton from "components/layout/ScifiButton";
import TritBalance from "./TritBalance";

interface SlotStakeProps {
  slot: number
  lock: string
  apr: string
  offer: string
  totalStaked: number
  image: string
  connected: boolean
  stakeAction: any
  claimAction: any
  unstakeAction: any
  stakeDetails: any
  setAmount: any
  setSlot: any
  reward: string
  tritBalance: number
}

const SlotStake = (props: SlotStakeProps) => {

  const [amount1, setAmount1] = useState<number>(0);
  const [amount2, setAmount2] = useState<number>(0);
  const [amount3, setAmount3] = useState<number>(0);

  const inputAmount = useRef<HTMLInputElement>(null);

  const updateAmount = () => {
    switch (props.slot) {
      case 1:
        props.setAmount(amount1);
        break;
      case 2:
        props.setAmount(amount2);
        break;
      case 3:
        props.setAmount(amount3);
        break;
    }
  }

  const formatCoin = (value: number | string) => {
    return new Intl.NumberFormat().format(Number(value) / 1000000);
  }

  const clearField = () => {
    inputAmount.current!.value = '';
    setAmount1(0);
    setAmount2(0);
    setAmount3(0);
    props.setAmount(0);
  }


  const maxAmount = () => {
    inputAmount.current!.value = props.tritBalance.toString();
    switch (props.slot) {
      case 1:        
        setAmount1(props.tritBalance);
        props.setAmount(props.tritBalance);
        break;
      case 2:
        setAmount2(props.tritBalance);
        props.setAmount(props.tritBalance);
        break;
      case 3:
        setAmount3(props.tritBalance);
        props.setAmount(props.tritBalance);
        break;
    }
  }

  return (
    <div className="stakeSlot">
      <Row>
        <Col>
          <div className="stakeImage"><img src={props.image} width={200}/></div>
          <div className="stakeDesc">
            <div className="stakeSlotInfos">
              <span className="stakeLabel">APR</span> <span className="stakeDescValueTag"><b>{props.apr}</b></span><br/><br/>
              <span className="stakeLabel">Lock period</span> <span className="stakeDescValue">{props.lock}</span><br/>
              <span className="stakeLabel">Offer available</span> <span className="stakeDescValue">{props.offer != "" ? formatCoin(props.offer): "connect wallet"}</span>
            </div>
            <div className="stakeMyInfos">
              <span className="stakeLabel">My Staked</span>
              <div
                className="stakeDescValueTag2"
                onClick={()=>{
                  if (props.totalStaked > 0) {
                    props.stakeDetails(props.slot)
                  }
                }}
              >
                <b>{formatCoin(props.totalStaked)} <span style={{fontSize: "12px"}}>TRIT</span></b>
              </div>
              <span className="stakeLabel">Reward</span> <span className="stakeDescValue">{formatCoin(props.reward)} <span style={{fontSize: "11px"}}>TRIT</span></span>
            </div>
            <div className="valueStake">
              <span className="valueMiniButton" onClick={maxAmount}>max</span>
              <span>
                <input
                  ref={inputAmount}
                  style={{width: '100%'}} 
                  onInput={
                    (e: any) => {
                      props.setAmount(e.target.value);
                      props.setSlot(props.slot);
                      switch (props.slot) {
                        case 1:
                          setAmount1(e.target.value);
                          break;
                        case 2:
                          setAmount2(e.target.value);
                          break;
                        case 3:
                          setAmount3(e.target.value);
                          break;
                      }
                    }
                  } 
                  type="number" 
                  placeholder="Amount" 
                />
              </span>
            </div>
            {props.connected && (
              <div style={{textAlign: 'center', paddingTop: '6px'}}>
                <ScifiButton
                  style={{height: '44px'}}
                  disabled={!props.connected} 
                  onClickCapture={()=>{
                    props.setSlot(props.slot);
                    updateAmount();
                  }} 
                  onClick={() => {props.stakeAction(); clearField();}}>
                    Stake
                </ScifiButton>
                &nbsp;
                <ScifiButton 
                  disabled={!props.connected || props.totalStaked <= 0}
                  onClickCapture={()=>{
                    props.setSlot(props.slot);
                    updateAmount();
                  }}
                  onClick={() => {props.unstakeAction(); clearField();}}>
                    Unstake
                </ScifiButton>
                &nbsp;
                <ScifiButton 
                  disabled={!props.connected || Number(props.reward) <= 0}
                  onClickCapture={()=>{
                    props.setSlot(props.slot);
                    updateAmount();
                  }}
                  onClick={() => {props.claimAction()}}>
                    Claim
                </ScifiButton>
              </div>
            )}
            
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default SlotStake;