import "./App.css";
import { useState } from "react";
import TopBar from "template/TopBar";
import Header from "template/Header";
import Content from "template/Content";
import Menu from "template/Menu";
import Footer from "template/Footer";
import ChangeCoin from "components/ChangeCoin";
import Stake from "components/Stake";

const App = () => {

  const [route, setRoute] = useState<string>('stake');

  return (
    <div className="App">
      <Header>
        <TopBar />
        <img className="logo" src="/tritium.png" alt="Tritium" />
      </Header>
      <Content>
        <Menu route={setRoute} />
        {route === 'swap' && (
          <ChangeCoin />
        )}
        {route === 'stake' && (
          <Stake />
        )}
      </Content>
      <Footer />
    </div>
  );
};

export default App;
