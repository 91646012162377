// sync-ed from root via `tr sync-refs`

import config from "../refs.terrain.json";
import { tritTokenContract } from "contract/config";

export const getContractAddress = (
  network = 'columbus-5',
  chainID: string
) => {
  try {
    const contractAddress = (config as any)[network]?.[chainID]?.[
      "swap"
    ].contractAddresses?.default;
    if (contractAddress) return contractAddress;
  } catch {}
};

export const tokenAddress = tritTokenContract;
