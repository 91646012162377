
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

interface CoinOptionProps {
  symbol: string;
  logo: string;
  name: string;
  setCoin: any;
  setAnchorEl: any;
  setDenom: any;
  setFee: any;
  setCoins: any;
}

const CoinOption = (props: CoinOptionProps) => {
  return (
    <div 
      className="coinOption"
      onClick={() => {
        props.setCoin(props.symbol);
        props.setDenom(props.symbol);
        props.setAnchorEl(null);
        props.setFee(0);
        props.setCoins(0);
      }}
    >
      <span className="logoCoin" style={{float: 'left', marginRight: '8px'}}>
        <img
          src={props.logo}
          alt={props.symbol}
        />
      </span>
      <div className="symbolCoin">
        {props.symbol}{" "}
        <span
          className="selectCoin"
          style={{ opacity: "0" }}
        >
          <KeyboardArrowDownIcon />
        </span>
      </div>
      <div className="nameCoin" style={{color: '#555555'}}>{props.name}</div>
    </div>
  );
}

export default CoinOption;