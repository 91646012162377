import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Box from "components/layout/Box";
import ScifiButton from "./layout/ScifiButton";
import WalletInfo from "./wallet/WalletInfo";
import TritBalance from "./TritBalance";
import * as execute from "../contract/execute";
import * as executeGalaxy from "../contract/executeGalaxy";
import { Popover } from "@mui/material";
import { useWallet, useConnectedWallet, WalletStatus, useLcdClient } from "@terra-money/wallet-kit";
import { useWallet as useWalletGalaxy, WalletStatus as WalletStatusGalaxy, useConnectedWallet as useConnectedWalletGalaxy, useLcdClient as useLcdClientGalaxy } from "@hexxagon/wallet-kit";
import { Row, Col } from "components/layout/Grid";
import CoinOption from "./CoinOption";
import SelectedCoin from "./SelectedCoin";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Coins } from "@terra-money/feather.js";
import { Coins as CoinsGalaxy } from "@hexxagon/feather.js";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { tritReferenceWallet, ulunaPrice, uusdPrice, nftRequire } from "../contract/config";
import { nftPresent } from "contract/query";

const ChangeCoin = () => {

  const [tritium, setTritium] = useState<any>(0)
  const [openDialog, setOpenDialog] = useState(false);
  const [message, setMessage] = useState<any>("");
  const [selectedCoin, setSelectedCoin] = useState("LUNC")
  const [anchorEl, setAnchorEl] = useState(null);
  const [coins, setCoins] = useState(0);
  const [denom, setDenom] = useState("LUNC");
  const [searchParams] = useSearchParams();
  const [statusReq, setStatusReq] = useState("Success");
  const [clicked, setClicked] = useState(false);
  const open = Boolean(anchorEl);
  const [isWalletConnected] = useState(false);
  const [nftHere, setNftHere] = useState(false);
  const [fee, setFee] = useState<number>(0);
  const [luncBalance, setLuncBalance] = useState<number>(0);
  const [ustcBalance, setUstcBalance] = useState<number>(0);
  const [onSimulate, setOnSimulate] = useState<boolean>(false);

  let addressReference = searchParams.get("ref");

  const wallet = useWallet();
  const { status } = wallet;
  const connected = useConnectedWallet();
  const lcd = useLcdClient();

  const walletGalaxy = useWalletGalaxy();
  const connectedGalaxy = useConnectedWalletGalaxy();
  const { status: statusGalaxy } = walletGalaxy;

  useEffect(() => {
    if (connected && nftRequire) {
      nftPresent(connected?.addresses['columbus-5']).then((result: any) => {
        if (result.tokens.length > 0) {
          setNftHere(true);
        }
      });
    }
  }, [connected]);

  const handleClick = (e: any) => {
    setAnchorEl(e.currentTarget);
    setTritium(0);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDilogClose = () => {
    setOpenDialog(false);
  }

  const handleReferrer = () => {
    setStatusReq("Referrer Link");
    setMessage(
      <div>
        <p>
          Share the referral link to recommend purchasing Tritium Token to your friends. This way, you can earn 
          &nbsp;<b>3% in Tritium + 3% in (LUNC or USTC)</b> for every swap made through your referral
        </p>

        <p>
          Your referral link<br/>
          {((connected)) && (
             <div className="youRefferr">{"https://swap.terratritium.com?ref="}{connected.addresses["columbus-5"]}</div>
          )}
          {((connectedGalaxy)) && (
             <div className="youRefferr">{"https://swap.terratritium.com?ref="}{connectedGalaxy.addresses["columbus-5"]}</div>
          )}
          {((!connected && !connectedGalaxy)) && (
             <div className="youRefferr">---</div>
          )}
        </p>

        <p>Spread the word about this opportunity and enjoy the benefits!</p>
      </div>
    );
    setOpenDialog(true);
  }

  const formatBalance = (balance: number) => {
    const parts = balance.toString().split('.');
    const integerPart = parts[0];
    const decimalPart = (parts[1] ? parts[1].padEnd(6, '0') : '000000').slice(0, 6);
    return (
      <span>
        {integerPart}.<span className="decimalPartLunc">{decimalPart}</span>
      </span>
    );
  }

  function calcTrit(value: number, type: string) {
    setCoins(value * 1000000);
    if (type === "LUNC") {
      setDenom("LUNC");
      return (value * ulunaPrice).toFixed(6);
    }
    if (type === "USTC") {
      setDenom("USTC");
      return (value * uusdPrice).toFixed(6);
    }
    return 0
  }

  const simulateTx = async (value?: number) => {
    setOnSimulate(true);
    if ((connected || connectedGalaxy) && (nftRequire ? nftHere : true)) {
      let resp;

      if (addressReference === null) {
        addressReference = tritReferenceWallet;
      }

      const finalCoins = coins > 0 ? coins : (value || 0) * 1000000;

      try {
        if (connected) {
          if (denom === "LUNC") {
            resp = await execute.simulateSwap(wallet, connected, "columbus-5", new Coins({ uluna: finalCoins }), addressReference);
            let feeCoin = resp.fee?.amount.get("uluna")?.toData()?.amount || "0";
            if (feeCoin !== "0") {
              const finalFeeCoin = parseFloat(feeCoin) / 1000000;
              setFee(finalFeeCoin);
            }
          } else {
            resp = await execute.simulateSwap(wallet, connected, "columbus-5", new Coins({ uusd: finalCoins }), addressReference);
            let feeCoin = resp.fee?.amount.get("uusd")?.toData()?.amount || "0";
            if (feeCoin !== "0") {
              const finalFeeCoin = parseFloat(feeCoin) / 1000000;
              setFee(finalFeeCoin);
            }
          }
        }
      } catch (err: any) {
        console.log(err);
      }

      try {
        if (connectedGalaxy) {
          if (denom === "LUNC") {
            resp = await executeGalaxy.simulateSwap(walletGalaxy, connectedGalaxy, "columbus-5", new CoinsGalaxy({ uluna: finalCoins }), addressReference);
            let feeCoin = resp.fee?.amount.get("uluna")?.toData()?.amount || "0";
            if (feeCoin !== "0") {
              const finalFeeCoin = parseFloat(feeCoin) / 1000000;
              setFee(finalFeeCoin);
            }
          } else {
            resp = await executeGalaxy.simulateSwap(walletGalaxy, connectedGalaxy, "columbus-5", new CoinsGalaxy({ uusd: finalCoins }), addressReference);
            let feeCoin = resp.fee?.amount.get("uusd")?.toData()?.amount || "0";
            if (feeCoin !== "0") {
              const finalFeeCoin = parseFloat(feeCoin) / 1000000;
              setFee(finalFeeCoin);
            }
          }
        }
      } catch (err: any) {
        console.log(err);
      }
    }
    setOnSimulate(false);
  }

  const onClickSwap = async () => {
    setClicked(true);

    if ((connected || connectedGalaxy) && (nftRequire ? nftHere : true)) {

      if (coins <= 0) {
        setStatusReq("Error");
        setMessage("Please enter a value");
        setOpenDialog(true);
        setClicked(false);
        return;
      }

      if (denom === "LUNC") {
        if ((((luncBalance - coins) / 1000000) - fee) < 0) {
          setStatusReq("Error");
          setMessage("Insufficient funds");
          setOpenDialog(true);
          setClicked(false);
          return;
        }
      }

      if (denom === "USTC") {
        if ((((ustcBalance - coins) / 1000000) - fee) < 0) {
          setStatusReq("Error");
          setMessage("Insufficient funds");
          setOpenDialog(true);
          setClicked(false);
          return;
        }
      }

      try {
        let resp;
        if (addressReference === null) {
          addressReference = tritReferenceWallet;
        }

        if (connected) {
          if (denom === "LUNC") {
            resp = await execute.swap(wallet, connected, "columbus-5", new Coins({ uluna: coins }), addressReference);
          } else {
            resp = await execute.swap(wallet, connected, "columbus-5", new Coins({ uusd: coins }), addressReference);
          }
        }

        if (connectedGalaxy) {
          if (denom === "LUNC") {
            resp = await executeGalaxy.swap(walletGalaxy, connectedGalaxy, "columbus-5", new CoinsGalaxy({ uluna: coins }), addressReference);
          } else {
            resp = await executeGalaxy.swap(walletGalaxy, connectedGalaxy, "columbus-5", new CoinsGalaxy({ uusd: coins }), addressReference);
          }
        }

        if (resp.txhash !== undefined) {
          setStatusReq("Success");
          setMessage(resp.txhash);
        } else {
          setStatusReq("Error");
          setMessage(resp.raw_log);
        }
        setOpenDialog(true);
      } catch (err: any) {
        console.log(err)
        setStatusReq("Error");
        if (err.response.data.code == 2) {
          if (err.response.data.message.includes("invalid to address")) {
            setMessage("Invalid ref address");
          } else if (err.response.data.message.includes("Overflow: Cannot Sub with")) {
            setMessage("No more tokens available for this transaction");
          } else if (err.response.data.message.includes("Insufficient funds: execute wasm contract failed")) {
            setMessage("At least 1 LUNC or 1 USTC");
          } else if (err.response.data.message.includes("message index: 0: 0uusd")) {
            setMessage("Insufficient USTC");
          } else {
            setMessage("Please check your transaction");
          }
        }
        if (err.response.data.code == 5) {
          setMessage("Invalid address");
        }
        setOpenDialog(true);
      }
      setClicked(false);
    }
  };

  const preSales = [
    { phase: "Pre-Sale 1 (Private and NFTs Holders)", price: "100% SOLD OUT" },
    { phase: "Pre-Sale 2", price: "100% SOLD OUT" },
    { phase: "Pre-Sale 3", price: "≃ 0.003 USD" },
    { phase: "Regular Sale", price: "≃ 0.005 USD" }
  ];

  return (
    <Box>
      {(nftRequire ? nftHere : true) && (status === WalletStatus.CONNECTED || statusGalaxy === WalletStatusGalaxy.CONNECTED) && (
        <>
          <TritBalance />
          <WalletInfo ulunaBalance={setLuncBalance} uusdBalance={setUstcBalance} />
        </>
      )}
      {(status !== WalletStatus.CONNECTED && statusGalaxy !== WalletStatusGalaxy.CONNECTED) && (
        <>
          <br />
          <br />
        </>
      )}
      {!(nftRequire ? nftHere : true) && (status === WalletStatus.CONNECTED || statusGalaxy === WalletStatusGalaxy.CONNECTED) && (
        <div className="nftAlert">
          No NFT present in this wallet. Please purchase an NFT to participate in this phase
          <br />
          <br />
          <a href="https://miata.io/creators/32/collection/99" target="_blank">Buy your NFT here!</a>
          <br />
        </div>
      )}
      <div>
        <div className={((status === WalletStatus.CONNECTED || statusGalaxy === WalletStatusGalaxy.CONNECTED) && !isWalletConnected) ? "coinBox" : "coinBoxDisabled"}>
          {selectedCoin === "LUNC" && (
            <SelectedCoin
              logo="/coins/lunc.svg"
              symbol="LUNC"
              name="Terra Classic"
              calcTrit={calcTrit}
              setTritium={setTritium}
              click={handleClick}
              simulate={simulateTx}
            />
          )}
          {selectedCoin === "USTC" && (
            <SelectedCoin
              logo="/coins/ustc.png"
              symbol="USTC"
              name="Terra Stablecoin"
              calcTrit={calcTrit}
              setTritium={setTritium}
              click={handleClick}
              simulate={simulateTx}
            />
          )}
        </div>
        <div style={{ opacity: '0.3' }}>
          <ArrowDownwardIcon />
        </div>
        <div className={((status === WalletStatus.CONNECTED || statusGalaxy === WalletStatusGalaxy.CONNECTED) && !isWalletConnected) ? "coinBox" : "coinBoxDisabled"}>
          <Row>
            <Col>
              <span className="logoCoin">
                <img
                  src="https://raw.githubusercontent.com/terra-tritium/assets/main/trit.png"
                  alt="trit"
                />
              </span>
            </Col>
            <Col>
              <Row>
                <Col>
                  <div className="symbolCoin">
                    TRIT{" "}
                    <span
                      className="selectCoin"
                      style={{ opacity: "0" }}
                    >
                      <KeyboardArrowDownIcon />
                    </span>
                  </div>
                  <div className="nameCoin">Tritium Token</div>
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <span className="valueCoinTrit">
                <input type="number" disabled value={tritium.toString().split('.')[0]} />
                <span className="decimalPart">
                  .{tritium.toString().split('.')[1] || '000000'}
                </span>
              </span>
            </Col>
          </Row>
        </div>
      </div>
      <div className="feeInfo">
        {fee > 0 && (
          <div style={{ paddingBottom: "12px" }}>Fee ≃ <span className="feeNumber">{formatBalance(fee)} {" "} {denom}</span></div>
        )}
        {luncBalance > 0 && denom === "LUNC" && (
          <div>Balance after tx ≃ <span className={(((((luncBalance - coins) / 1000000) - fee) - 100 - ((coins/1000000)*0.005)) > 0) ? "feeNumber" : "redNumber"}>{formatBalance((((luncBalance - coins) / 1000000) - fee) - 100 - ((coins/1000000)*0.005))} LUNC</span></div>
        )}
        {ustcBalance > 0 && denom === "USTC" && (
          <div>Balance after tx ≃ <span className={(((((ustcBalance - coins) / 1000000) - fee) - 100 - ((coins/1000000)*0.005)) > 0) ?  "feeNumber": "redNumber"}>{formatBalance((((ustcBalance - coins) / 1000000) - fee) - 100 - ((coins/1000000)*0.005))} USTC</span></div>
        )}
      </div>
      <div className="btnSwap">
        <ScifiButton
          disabled={
            (
              (status === WalletStatus.CONNECTED || statusGalaxy === WalletStatusGalaxy.CONNECTED)
              && !isWalletConnected
              && (nftRequire ? nftHere : true)
            ) ? 
            (clicked ? true :
              onSimulate ? 
                true : 
                (fee > 0 ? false : true)
            ) : true
          }
          onClick={onClickSwap}
        >{clicked ? "Wait transacting..." : onSimulate ? "Loading..." : "SWAP"}</ScifiButton>
      </div>
      <br />
      <div className="reference" onClick={handleReferrer}>
        Referrer: <span className="addressRef">{addressReference || tritReferenceWallet}</span>
      </div>
      <br />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: '#cccccc',
            boxShadow: 'none',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <div className="coinsOptions">
          <CoinOption
            logo="/coins/lunc.svg"
            symbol="LUNC"
            name="Terra Classic"
            setCoin={setSelectedCoin}
            setAnchorEl={setAnchorEl}
            setDenom={setDenom}
            setFee={setFee}
            setCoins={setCoins}
          />
          <CoinOption
            logo="/coins/ustc.png"
            symbol="USTC"
            name="Terra Stablecoin"
            setCoin={setSelectedCoin}
            setAnchorEl={setAnchorEl}
            setDenom={setDenom}
            setFee={setFee}
            setCoins={setCoins}
          />
        </div>
      </Popover>
      <Dialog
        open={openDialog}
        onClose={handleDilogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{statusReq}</DialogTitle>
        <DialogContent>
          <div className="dialogContent">
            {statusReq === "Referrer Link" && (
              <div className="textRefLink">{message}</div>
            )}
            {statusReq === "Success" && (
              <div>TXHASH</div>
            )}
            <div style={{ fontSize: '10px' }}>
              {statusReq === "Success" && (
                <a href={"https://finder.terra.money/classic/tx/" + message} target="blank">
                  {message}
                </a>
              )}
              {statusReq === "Error" && (
                <span>{message}</span>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDilogClose} autoFocus>Ok</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ChangeCoin;