import { PropsWithChildren } from "react"

interface Props {

}

const Box = ({ children }: PropsWithChildren<Props>) => {

  return (
    <div className="box">
      <div className="scifi">
        <div className="helper1"></div>
        {children}
      </div>
    </div>
  );
};

export default Box;