import { useEffect, useState } from "react";
import Box from "components/layout/Box";
import TritBalance from "./TritBalance";
import SlotStake from "./SlotStake";
import { useWallet, WalletStatus, useConnectedWallet } from "@terra-money/wallet-kit";
import { useWallet as useWalletGalaxy, WalletStatus as WalletStatusGalaxy, useConnectedWallet as useConnectedWalletGalaxy, useLcdClient as useLcdClientGalaxy } from "@hexxagon/wallet-kit";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import ExploreIcon from '@mui/icons-material/Explore';
import { LinearProgress } from "@mui/material";
import { tritStakeContract } from "contract/config";
import { Coins } from "@terra-money/feather.js";
import { Coins as CoinsGalaxy } from "@hexxagon/feather.js";
import * as execute from "../contract/execute";
import * as executeGalaxy from "../contract/executeGalaxy";
import axios from "axios";

const Stake = () => {
  const walletStation = useWallet();
  const { status } = walletStation;
  const walletGalaxy = useWalletGalaxy();
  const { status: statusGalaxy } = walletGalaxy;

  const connectedStation = useConnectedWallet();
  const connectedGalaxy = useConnectedWalletGalaxy();

  const [connected, setConnected] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [statusReq, setStatusReq] = useState("Success");
  const [message, setMessage] = useState<any>("");
  const [amount, setAmount] = useState<number>(0);
  const [slot, setSlot] = useState<number>(1);
  const [tritBalance, setTritBalance] = useState<number>(0);
  const [stakeConf, setStakeConf] = useState<any>(undefined);
  const [myStake01, setMyStake01] = useState<any>(undefined);
  const [myStake02, setMyStake02] = useState<any>(undefined);
  const [myStake03, setMyStake03] = useState<any>(undefined);
  const [details01, setDetails01] = useState<any>(undefined);
  const [details02, setDetails02] = useState<any>(undefined);
  const [details03, setDetails03] = useState<any>(undefined);
  const [totalStaked01, setTotalStaked01] = useState<number>(0);
  const [totalStaked02, setTotalStaked02] = useState<number>(0);
  const [totalStaked03, setTotalStaked03] = useState<number>(0);

  const handleDilogClose = () => {
    setOpenDialog(false);
  }

  const unstake01 = () => {
    unstake("stake01");
  }

  const unstake02 = () => {
    unstake("stake02");
  }

  const unstake03 = () => {
    unstake("stake03");
  }

  const claim01 = () => {
    claim("stake01");
  }

  const claim02 = () => {
    claim("stake02");
  }

  const claim03 = () => {
    claim("stake03");
  }

  const stake01 = async () => {
    stake("stake01");
  }

  const stake02 = async () => {
    stake("stake02");
  }

  const stake03 = async () => {
    stake("stake03");
  }

  const stake = async (slot: string) => {
    executeTX({
      send: {
        contract: tritStakeContract,
        amount: Math.floor(amount * Math.pow(10, 6)).toFixed(0),
        msg: Buffer.from(JSON.stringify({
          "stake":{"slot": slot}
        })).toString('base64')
      }
    }, "stake");
  }

  const unstake = async (slot: string) => {
    executeTX({
      unstake: {
        slot: slot,
        amount: Math.floor(amount * Math.pow(10, 6)).toFixed(0),
      }
    }, "unstake");
  }

  const claim = async (slot: string) => {
    executeTX({
      claim: {
        slot: slot,
      }
    }, "claim");
  }

  const executeTX = async (msg: any, action: string) => {
    if (!connected) {
      setStatusReq("Error");
      setOpenDialog(true);
      setMessage("Connect your wallet");
      return;
    }

    if (action != "claim") {
      if (Number(amount) === 0 || amount === undefined) {
        setStatusReq("Error");
        setOpenDialog(true);
        setMessage("Amount is required");
        return;
      }
  
      if (Number(amount) > Number(tritBalance) && action != "unstake") {
        setStatusReq("Error");
        setOpenDialog(true);
        setMessage("Insufficient TRIT");
        return;
      }
    }

    try {

      setStatusReq("Loading");
      setOpenDialog(true);

      // close loading timeout
      setTimeout(() => {
        setOpenDialog(false);
      }, 20000);
      
      if (connectedStation) {
        const resp = 
          action == "stake" ? 
          await execute.token(walletStation, connectedStation, "columbus-5", new Coins(), msg) : 
          await execute.stake(walletStation, connectedStation, "columbus-5", new Coins(), msg);
        
        if (resp.txhash !== undefined) {
          setStatusReq("Success");
          setMessage(resp.txhash);
        } else {
          setStatusReq("Error");
          setMessage(resp.raw_log);
        }
      }

      if (connectedGalaxy) {
        const resp = 
          action == "stake" ? 
          await executeGalaxy.token(walletGalaxy, connectedGalaxy, "columbus-5", new CoinsGalaxy(), msg) : 
          await executeGalaxy.stake(walletGalaxy, connectedGalaxy, "columbus-5", new CoinsGalaxy(), msg);
        
        if (resp.txhash !== undefined) {
          setStatusReq("Success");
          setMessage(resp.txhash);
        } else {
          setStatusReq("Error");
          if (resp == "User denied") {
            setMessage("Transaction canceled");
          } else {
            setMessage(resp.raw_log);
          }
        }
      }

      setOpenDialog(true);

    } catch (error: any) {
      setStatusReq("Error");
      setMessage(error.message);
      
      if ( error.response.data.message.includes("failed to execute message; message index: 0: dispatch: submessages: Not enough rewards balance") ) {
        setMessage("Not enough rewards balance to new stake");
      } 
      if ( error.response.data.message.includes("failed to execute message; message index: 0: dispatch: submessages: This staking slot only has") ) {
        setMessage("No enough offer available");
      }
      if ( error.response.data.message.includes("failed to execute message; message index: 0: Not enough rewards balance")) {
        setMessage("Not enough rewards balance");
      }
      if ( error.response.data.message.includes("failed to execute message; message index: 0: Tokens staked and unlocked")) {
        setMessage("Tokens still in lock period");
      }
      if ( error.response.data.message.includes("rpc error: code = NotFound desc = account")) {
        setMessage("Account does not have enough LUNC to pay transaction fee");
      }
      if ( error.response.data.message.includes("failed to execute message; message index: 0: Nothing staked")) {
        setMessage("Nothing token staked");
      }

      setOpenDialog(true);
    }
  }

  const stakeDetails = (slot: number) => {
    setStatusReq("Stake Details");

    let detailsList = undefined;

    switch (slot) {
      case 1:
        detailsList = details01;
        break;
      case 2:
        detailsList = details02;
        break;
      case 3:
        detailsList = details03;
        break;
    }

    setMessage(mountDetails(detailsList));
    setOpenDialog(true);
  }

  const mountDetails = (details: any) => {
    let detailsList: any[] = [];
    details.forEach((element: any) => {
      detailsList.push(
        <div className="stakeUnitDetail">
          <div><b>Amount: { new Intl.NumberFormat().format(Number(element.amount) / 1000000) } TRIT</b></div>
          <div>Start: {new Date(element.stake_time / 1000000).toDateString()}</div>
          <div>Unlock: {new Date(element.unlock_time / 1000000).toDateString()}</div>
        </div>
      );
    });
    return detailsList;
  }

  const exploreAction = () => {
    setStatusReq("Explore");
    setMessage(
      <>
        <p style={{textAlign: "justify"}}>Choose your spaceship according to your strategy to seek rewards in TRIT, a rare stellar mineral. Pay attention to the travel time as the ship will only return home after the lock period.</p>
      </>
    );
    setOpenDialog(true);
  }

  useEffect(() => {
    if (status === WalletStatus.CONNECTED || statusGalaxy === WalletStatusGalaxy.CONNECTED) {
      
      let myWallet = "";

      if (connectedStation) {
        myWallet = connectedStation.addresses['columbus-5'];
      }

      if (connectedGalaxy) {
        myWallet = connectedGalaxy.addresses['columbus-5'];
      }
      
      setConnected(true);
      const statkeConfigs = async () => {
        const resp = await axios.get("https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/"+tritStakeContract+"/smart/eyJjb25maWciOnt9fQ==");
        setStakeConf(resp.data.data.slots);
      }
      statkeConfigs();
      const myStake01Configs = async () => {
        const msg = Buffer.from("{\"staking_info\": { \"staker\": \""+myWallet+"\", \"slot\": \"stake01\"}}").toString('base64');
        const resp = await axios.get("https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/"+tritStakeContract+"/smart/"+msg);
        let total = 0;
        setDetails01(resp.data.data.staked.stakes);
        resp.data.data.staked.stakes.forEach((element: any) => {
          total += Number(element.amount);
        });
        setTotalStaked01(total);
        setMyStake01(resp.data.data);        
      }
      myStake01Configs();
      const myStake02Configs = async () => {
        const msg = Buffer.from("{\"staking_info\": { \"staker\": \""+myWallet+"\", \"slot\": \"stake02\"}}").toString('base64');
        const resp = await axios.get("https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/"+tritStakeContract+"/smart/"+msg);
        let total = 0;
        setDetails02(resp.data.data.staked.stakes);
        resp.data.data.staked.stakes.forEach((element: any) => {
          total += Number(element.amount);
        });
        setTotalStaked02(total);
        setMyStake02(resp.data.data);
      }
      myStake02Configs();
      const myStake03Configs = async () => {
        const msg = Buffer.from("{\"staking_info\": { \"staker\": \""+myWallet+"\", \"slot\": \"stake03\"}}").toString('base64');
        const resp = await axios.get("https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/"+tritStakeContract+"/smart/"+msg);
        let total = 0;
        setDetails03(resp.data.data.staked.stakes);
        resp.data.data.staked.stakes.forEach((element: any) => {
          total += Number(element.amount);
        });
        setTotalStaked03(total);
        setMyStake03(resp.data.data);
      }
      myStake03Configs();
    } else {
      setConnected(false);
    }
  }, [status, statusGalaxy]);

  return (
    <Box>
      {/* <div className="stakeIntroduction">
        {"Choose your spaceship according to your strategy to seek rewards in TRIT, a rare stellar mineral. Pay attention to the travel time as the ship will only return home after the lock period."}
      </div> */}
      {connected && (<div className="boxTritBalance"><TritBalance balance={setTritBalance} /></div>)}

      <div style={{textAlign: "right"}}>
        <span className="btnExplore" onClick={exploreAction}>
          Explore <span className="exploreIcon"><ExploreIcon /></span>
        </span>
      </div>
      
      <div className={connected ? "stakeBox" : "stakeBoxDisabled"}>
        <SlotStake 
          slot={1} 
          setSlot={setSlot}
          setAmount={setAmount}
          stakeAction={stake01}
          claimAction={claim01}
          unstakeAction={unstake01}
          stakeDetails={stakeDetails}
          connected={connected}
          lock={stakeConf ? stakeConf.stake01.lock_period + " " + "days" : "45 days"}
          apr={stakeConf ? ((stakeConf.stake01.apr)*100) + "%" : "8%"}
          offer={stakeConf ? stakeConf.stake01.stake_available : ""}
          reward={myStake01 != undefined ? myStake01.rewards : 0}
          totalStaked={totalStaked01}
          image="/ships/space1.webp"
          tritBalance={tritBalance}
        /> 
        <SlotStake 
          slot={2}
          setSlot={setSlot}
          setAmount={setAmount}
          stakeAction={stake02}
          claimAction={claim02}
          unstakeAction={unstake02}
          stakeDetails={stakeDetails}
          connected={connected}
          lock={stakeConf ? stakeConf.stake02.lock_period + " " + "days" : "180 days"}
          apr={stakeConf ? ((stakeConf.stake02.apr)*100) + "%" : "25%"}
          offer={stakeConf ? stakeConf.stake02.stake_available : ""}
          reward={myStake02 != undefined ? myStake02.rewards : 0}
          totalStaked={totalStaked02}
          image="/ships/space2.webp"
          tritBalance={tritBalance}
        /> 
        <SlotStake 
          slot={3}
          setSlot={setSlot} 
          setAmount={setAmount}
          stakeAction={stake03}
          claimAction={claim03}
          unstakeAction={unstake03}
          stakeDetails={stakeDetails}
          connected={connected}
          lock={stakeConf ? stakeConf.stake03.lock_period + " " + "days" : "365 days"}
          apr={stakeConf ? ((stakeConf.stake03.apr)*100) + "%" : "60%"}
          offer={stakeConf ? stakeConf.stake03.stake_available : ""}
          reward={myStake03 != undefined ? myStake03.rewards : 0}
          totalStaked={totalStaked03}
          image="/ships/space3.webp"
          tritBalance={tritBalance}
        />
      </div>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{statusReq}</DialogTitle>
        <DialogContent>
          <div className="dialogContent">
            {statusReq === "Referrer Link" && (
              <div className="textRefLink">{message}</div>
            )}
            {statusReq === "Success" && (
              <>
                <div>Transaction completed</div>
                <br/>
              </>
            )}
            {statusReq === "Stake Details" && (
              <>{message}</>
            )}
            {statusReq === "Explore" && (
              <>{message}</>
            )}
            <div style={{ fontSize: '10px' }}>
              {statusReq === "Success" && (
                <a href={"https://finder.terra.money/classic/tx/" + message} target="blank">
                  Finder TX: {message}
                </a>
              )}
              {statusReq === "Error" && (
                <span>{message != "" ? message : "Transaction canceled"}</span>
              )}
              {statusReq === "Loading" && (
                <span><LinearProgress /></span>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {statusReq != "Loading" && (
            <Button onClick={handleDilogClose} autoFocus>Ok</Button>
          )}
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Stake;