import "./TritBalance.css";
import { useEffect, useState } from "react";
// import { ConnectWallet } from "components/wallet/ConnectWallet";
import { LCDClient } from "@terra-money/feather.js";
import { columbus5 } from "contract/config";
import { useConnectedWallet, useWallet } from "@terra-money/wallet-kit";
import { useConnectedWallet as useConnectedWalletGalaxy } from "@hexxagon/wallet-kit";
// import TerraStationMobileWallet from "@terra-money/terra-station-mobile";
import { getTritBalance } from "contract/query";

interface TritBalanceProps {
  balance?: any
}

export const TritBalance = (props: TritBalanceProps) => {

  const [balance, setBalance] = useState<any>("0");  
  const connected = useConnectedWallet();
  const wallet = useWallet();

  const connectedGalaxy = useConnectedWalletGalaxy();

  // const walletMobile = new TerraStationMobileWallet()

  useEffect(() => {

    const tritBalance = async () => {
      const lcd = new LCDClient(columbus5);
      const lcdGalaxy = new LCDClient(columbus5);

      if (connected) {
        getTritBalance(connected?.addresses['columbus-5']).then((resultBalance: any) => {
          setBalance((resultBalance.balance / 1000000).toFixed(6));
          if (props.balance) {
            props.balance((resultBalance.balance / 1000000).toFixed(6));
          }
        });
      }

      if (connectedGalaxy) {
        getTritBalance(connectedGalaxy?.addresses['columbus-5']).then((resultBalance: any) => {
          setBalance((resultBalance.balance / 1000000).toFixed(6));
          if (props.balance) {
            props.balance((resultBalance.balance / 1000000).toFixed(6));
          }
        });
      }
     
    }
    tritBalance()
  }, [connected, connectedGalaxy]);
  
  return (
    <div className="balance">
      <span className="balanceTritSymbol">
        <img
          src="https://raw.githubusercontent.com/terra-tritium/assets/main/trit.png"
          alt="trit"
        />
      </span>
      <span className="balanceTritAmount">
        {balance.toString().split('.')[0]}.
        <span className="decimalPartBalance">{balance.toString().split('.')[1]}</span>
      </span>
    </div>
  );
};

export default TritBalance;