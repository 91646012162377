import { LCDClient, MsgExecuteContract, Coins, Coin, Fee, ExtensionOptions } from "@terra-money/feather.js";
import { ConnectResponse, WalletResponse } from "@terra-money/wallet-kit";
import { tritSwapContract } from "./config";
import { columbus5 } from "contract/config";
import { tritStakeContract } from "./config";
import { tritTokenContract } from "./config";

async function getTaxAmount(amount?: Coins) {
  if(!amount) {
    return undefined;
  }

  const taxRate = await(
    await fetch('https://terra-classic-lcd.publicnode.com/terra/treasury/v1beta1/tax_rate', {
      redirect: 'follow'
    })
  ).json();

  let taxes = new Coins();
  for(let coin of amount.toArray()) {
    const tax = coin.amount.toNumber() * taxRate.tax_rate;
    taxes = taxes.add(new Coin(coin.denom, tax));
  }
  
  return taxes;
}

async function getTransactionData(messages: any, senderAddress: string, funds: Coins, client: LCDClient, fcdUrl: string, chainID: string): Promise<ExtensionOptions> {

  const tax = await getTaxAmount(funds);

  const gasPrices = await(
    await fetch(fcdUrl + '/v1/txs/gas_prices', {})
  ).json();

  const use_fee_denoms = funds.denoms();

  if(use_fee_denoms.length === 0) {
    use_fee_denoms.push('uluna');
  }

  const gasAdjustment = 4;
  const gasPricesCoins = new Coins(gasPrices)

  const account = await client.auth.accountInfo(senderAddress);
  const signerDataArray = [{
    address: senderAddress,
    publicKey: account.getPublicKey(),
    sequenceNumber: account.getSequenceNumber()
  }];

  var txFee = await client.tx.estimateFee(
    signerDataArray, 
    {
      msgs: messages,
      gasPrices: gasPricesCoins,
      gasAdjustment: gasAdjustment,
      feeDenoms: use_fee_denoms,
      chainID: chainID
    }
  );
  
  let txdata : ExtensionOptions = {
    msgs: messages,
    feeDenoms: use_fee_denoms,
    chainID: chainID
  };

  if (tax) {
    txdata.fee = new Fee(txFee.gas_limit, txFee.amount.add(tax));
  }
  
  return txdata;
}

const simuleTx = (msg: any, chainID: string, coins: Coins) => async (wallet: WalletResponse, connected: ConnectResponse) => {
  const lcd = new LCDClient(columbus5);
  const contractAddress = tritSwapContract
  const walletAddress = connected.addresses[chainID]
  const execMsg = new MsgExecuteContract(walletAddress, contractAddress, msg, coins)

  return await getTransactionData([execMsg], walletAddress, coins, lcd, "https://terra-classic-fcd.publicnode.com", chainID);
}

const execTx =
  (msg: any, chainID: string, coins: Coins) => async (wallet: WalletResponse, connected: ConnectResponse) => {

    const lcd = new LCDClient(columbus5);
    const contractAddress = tritSwapContract
    const walletAddress = connected.addresses[chainID]
    const execMsg = new MsgExecuteContract(walletAddress, contractAddress, msg, coins)

    const txData = await getTransactionData([execMsg], walletAddress, coins, lcd, "https://terra-classic-fcd.publicnode.com", chainID);
  
    try {
      const tx = await wallet.post(txData);
      return tx;
    } catch (e: any) {
      return e;
    }
  };

const tokenTx = (msg: any, chainID: string, coins: Coins) => async (wallet: WalletResponse, connected: ConnectResponse) => {
  const lcd = new LCDClient(columbus5);
  const walletAddress = connected.addresses[chainID]
  const execMsg = new MsgExecuteContract(walletAddress, tritTokenContract, msg, coins)

  const txData = await getTransactionData([execMsg], walletAddress, coins, lcd, "https://terra-classic-fcd.publicnode.com", chainID);

  try {
    const tx = await wallet.post(txData);
    return tx;
  } catch (e: any) {
    return e;
  }
}

const stakeTx = (msg: any, chainID: string, coins: Coins) => async (wallet: WalletResponse, connected: ConnectResponse) => {
  const lcd = new LCDClient(columbus5);
  const walletAddress = connected.addresses[chainID]
  const execMsg = new MsgExecuteContract(walletAddress, tritStakeContract, msg, coins)

  const txData = await getTransactionData([execMsg], walletAddress, coins, lcd, "https://terra-classic-fcd.publicnode.com", chainID);

  try {
    const tx = await wallet.post(txData);
    return tx;
  } catch (e: any) {
    return e;
  }
}

// ==== execute contract ====

export const swap = (wallet: WalletResponse, connected: ConnectResponse, chainID: string, coins: Coins, reference: string) => {
  return execTx({ swap: {"referral": reference} }, chainID, coins)(wallet, connected);
}

export const simulateSwap = (wallet: WalletResponse, connected: ConnectResponse, chainID: string, coins: Coins, reference: string) => {
  return simuleTx({ swap: {"referral": reference} }, chainID, coins)(wallet, connected);
}

export const token = (wallet: WalletResponse, connected: ConnectResponse, chainID: string, coins: Coins, msg: any) => {
  return tokenTx(msg, chainID, coins)(wallet, connected);
}

export const stake = (wallet: WalletResponse, connected: ConnectResponse, chainID: string, coins: Coins, msg: any) => {
  return stakeTx(msg, chainID, coins)(wallet, connected);
}
  
