import { PropsWithChildren } from "react"

interface Props {

}

const Header = ({ children }: PropsWithChildren<Props>) => {

  return (
    <div className="Header">
      {children}
    </div>
  );
};

export default Header;