import { PropsWithChildren } from "react"

interface RowProps {
  span?: number
}

export const Row = (props: PropsWithChildren<RowProps>) => {
  const { children } = props
  return <div className="Row">{children}</div>
}

interface ColProps {
  span?: number
}

export const Col = ({ span = 1, children }: PropsWithChildren<ColProps>) => {
  return (
    <div className="Col" style={{ flex: span }}>
      {children}
    </div>
  )
}