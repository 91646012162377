import "./WalletInfo.css";
import { useState } from "react";
import { useWallet, useConnectedWallet, WalletStatus, useLcdClient } from "@terra-money/wallet-kit";
import { useWallet as useWalletGalaxy, WalletStatus as WalletStatusGalaxy, useLcdClient as useLcdClientGalaxy, useConnectedWallet as useConnectedWalletGalaxy } from "@hexxagon/wallet-kit";

interface BalanceProps {
  ulunaBalance: any
  uusdBalance: any
}

export const WalletInfo = (props: BalanceProps) => {

  const { status } = useWallet();
  const connected = useConnectedWallet();
  const lcd = useLcdClient();

  const { status: statusGalaxy } = useWalletGalaxy();
  const connectedGalaxy = useConnectedWalletGalaxy();
  const lcdGalaxy = useLcdClientGalaxy();

  const [balanceUluna, setBalanceUluna] = useState(0);
  const [balanceUusd, setBalanceUusd] = useState(0);
  
  connected &&
    lcd.bank.balance(connected.addresses['columbus-5']).then((Coins) => {
      Coins[0].map((coin) => {
        if (coin.amount) {
          if (coin.denom === 'uluna') {
            setBalanceUluna(Number(coin.amount))
            props.ulunaBalance(Number(coin.amount))
          }
          if (coin.denom === 'uusd') {
            setBalanceUusd(Number(coin.amount))
            props.uusdBalance(Number(coin.amount))
          }
        }
      });
    });


  connectedGalaxy &&
    lcdGalaxy.bank.balance(connectedGalaxy.addresses['columbus-5']).then((Coins) => {
      Coins[0].map((coin) => {
        if (coin.amount) {
          if (coin.denom === 'uluna') {
            setBalanceUluna(Number(coin.amount))
            props.ulunaBalance(Number(coin.amount))
          }
          if (coin.denom === 'uusd') {
            setBalanceUusd(Number(coin.amount))
            props.uusdBalance(Number(coin.amount))
          }
        }
      });
    });

  const formatBalance = (balance: number) => {
    const parts = balance.toString().split('.');
    const integerPart = parts[0];
    const decimalPart = parts[1] ? parts[1].padEnd(6, '0') : '000000';
    return (
      <span>
        {integerPart}.<span className="decimalPartLunc">{decimalPart}</span>
      </span>
    );
  }

  return (
    <div className="walletInfo">
      {
        (status === WalletStatus.NOT_CONNECTED && statusGalaxy === WalletStatusGalaxy.NOT_CONNECTED)
          ? <div>Please, connect your wallet.</div>
          : <div>Status: Connected</div>
      }
      {status === WalletStatus.CONNECTED && connected && (
        <div>
          <div>{connected.name} [{"terra..." + connected.addresses['columbus-5'].substr(-8)}]</div>
          <div>Network: {connected.network}</div>
          <br />
          <div>
            <span className="balanceLunc">{formatBalance(balanceUluna / 1000000)} LUNC</span>
            <span className="balanceUstc">{formatBalance(balanceUusd / 1000000)} USTC</span>
          </div>
        </div>
      )}

      {statusGalaxy === WalletStatusGalaxy.CONNECTED && connectedGalaxy && (
        <div>
          <div>{connectedGalaxy.name} [{"terra..." + connectedGalaxy.addresses['columbus-5'].substr(-8)}]</div>
          <div>Network: {connectedGalaxy.network}</div>
          <br />
          <div><span className="balanceLuncGalaxy">{formatBalance(balanceUluna / 1000000)} LUNC</span></div>
          <div><span className="balanceUstcGalaxy">{formatBalance(balanceUusd / 1000000)} USTC</span></div>
        </div>
      )}
    </div>
  );
};

export default WalletInfo;