import { useEffect, useState } from "react";
import { ConnectWallet } from "components/wallet/ConnectWallet";
import axios from "axios";

const TopBar = () => {

  const initialSupply = 495000000
  const initialCirculateSupply = 136600000
  const [totalSupply, setTotalSupply] = useState(initialSupply)
  const [addressBalance, setAddressBalance] = useState(0)

  useEffect(() => {
    axios.get('https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/terra1g6fm3yu79gv0rc8067n2nnfpf0vks6n0wpzaf4u7w48tdrmj98zsy7uu00/smart/eyJ0b2tlbl9pbmZvIjp7fX0=')
      .then(res => {
        setTotalSupply(res.data.data.total_supply / 1000000)
      })

      axios.get('https://terra-classic-lcd.publicnode.com/cosmwasm/wasm/v1/contract/terra1g6fm3yu79gv0rc8067n2nnfpf0vks6n0wpzaf4u7w48tdrmj98zsy7uu00/smart/eyJiYWxhbmNlIjp7ImFkZHJlc3MiOiJ0ZXJyYTEyaDV5ZXhxcjM5ZzcwMjV6MDJmd3o2MGVrNXF5dXkzdHB1eXR1Yzh3N3g1bWxqMm1zM2ZxMHBzeDc2In19')
      .then(res => {        
        setAddressBalance(res.data.data.balance / 1000000);
      });
  }, [])

  const adjustedCirculateSupply = initialCirculateSupply - (initialSupply - totalSupply) - addressBalance

  return (
    <div className="TopBar">
      <div className="TopBarContent">
        <ConnectWallet />
        <div className="supply">
          <img width={20} src="https://raw.githubusercontent.com/terra-tritium/assets/main/trit.png" alt="TRIT" />
          <div>
            <span className="valueSupply"><span style={{fontSize: 11}}>Total Supply:</span> {new Intl.NumberFormat('en-EN', { maximumFractionDigits: 0 }).format(totalSupply)}</span>
            <br/>
            <span className="valueSupply"><span style={{fontSize: 11}}>Circulation Supply:</span> {new Intl.NumberFormat('en-EN', { maximumFractionDigits: 0 }).format(adjustedCirculateSupply)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;